import { Routes } from "@angular/router";
import { webRoutes } from "./core/constants/routes";
import { AuthComponent } from "./layouts/auth/auth.component";
import { HomeComponent } from "./pages/home/home.component";
import { SignUpAIComponent } from "./pages/signup-ai/signup-ai.component";
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { ServicesComponent } from "./pages/services/services.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { authGuard } from "./shared/guards/auth.guard";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { HomeGreenComponent } from "./pages/home-green/home-green.component";
import { HomePinkComponent } from "./pages/home-pink/home-pink.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: webRoutes.home,
    pathMatch: "full",
  },
  {
    path: "home-green",
    component: HomeGreenComponent,
    data: { title: "Home Green" },
  },
  {
    path: "home-pink",
    component: HomePinkComponent,
    data: { title: "Home Pink" },
  },
  {
    path: webRoutes.signup,
    component: SignUpAIComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/signup-ai/signup-ai.component").then(
            (m) => m.SignUpAIComponent
          ),
        data: { title: "SignUp" },
      },
    ],
  },
  // home
  {
    path: webRoutes.home,
    component: HomeComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/home/home.component").then((m) => m.HomeComponent),
        data: { title: "home" },
      },
    ],
  },
  {
    path: webRoutes.services,
    component: ServicesComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/services/services.component").then(
            (m) => m.ServicesComponent
          ),
        data: { title: "Services" },
      },
    ],
  },
  {
    path: webRoutes.contact,
    component: ContactComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/contact/contact.component").then(
            (m) => m.ContactComponent
          ),
        data: { title: "Contact-Us" },
      },
    ],
  },
  {
    path: webRoutes.policy,
    children: [
      {
        path: "privacy-policy",
        loadComponent: () =>
          import("./pages/policy/privacy-policy/privacy-policy.component").then(
            (m) => m.PrivacyPolicyComponent
          ),
        data: { title: "Privacy Policy" },
      },
      {
        path: "terms-and-conditions",
        loadComponent: () =>
          import(
            "./pages/policy/terms-and-conditions/terms-and-conditions.component"
          ).then((m) => m.TermsAndConditionsComponent),
        data: { title: "Terms and Conditions" },
      },
      {
        path: "cookies-policy",
        loadComponent: () =>
          import("./pages/policy/cookies-policy/cookies-policy.component").then(
            (m) => m.CookiesPolicyComponent
          ),
        data: { title: "Cookie Policy" },
      },
    ],
  },
  // Auth
  {
    path: webRoutes.login,
    component: AuthComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/login/login.component").then((m) => m.LoginComponent),
        data: { title: "Login" },
      },
    ],
  },
  {
    path: `${webRoutes.verify}/:token`,
    loadComponent: () =>
      import("./pages/verify/verify.component").then((c) => c.VerifyComponent),
    data: { title: "Verify" },
  },
  {
    path: webRoutes.forgotPassword,
    loadComponent: () =>
      import("./pages/forgot-password/forgot-password.component").then(
        (m) => m.ForgotPasswordComponent
      ),
    data: { title: "Forgot Password" },
  },
  {
    path: `${webRoutes.resetPassword}/:token`,
    loadComponent: () =>
      import("./pages/reset-password/reset-password.component").then(
        (m) => m.ResetPasswordComponent
      ),
    data: { title: "Reset Password" },
  },
  {
    path: webRoutes.register,
    loadComponent: () =>
      import("./pages/register/register.component").then(
        (m) => m.RegisterComponent
      ),
    data: { title: "Register" },
  },
  {
    path: webRoutes.preregisterMarv,
    loadComponent: () =>
      import("./pages/preregister-marv/preregister-marv.component").then(
        (m) => m.PreregisterMarvComponent
      ),
    data: { title: "Marveler" },
  },
  {
    path: webRoutes.preregisterCo,
    loadComponent: () =>
      import("./pages/preregister-co/preregister-co.component").then(
        (m) => m.PreregisterCoComponent
      ),
    data: { title: "Counselor" },
  },
  {
    path: webRoutes.blog,
    loadComponent: () =>
      import("./pages/blogs/blog.component").then(
        (m) => m.BlogComponent
      ),
    data: { title: "Blog" },
  },
  {
    path: webRoutes.pressrelease,
    loadComponent: () =>
      import("./pages/press-release/press-release.component").then(
        (m) => m.PressReleaseComponent
      ),
    data: { title: "Press Release" },
  },
  {
    path: webRoutes.matchingTest,
    loadComponent: () =>
      import("./pages/matching-test/matching-test.component").then(
        (m) => m.MatchingTestComponent
      ),
    data: { title: "Matching Algo. Test" },
  },
  // {
  //   path: webRoutes.profile,
  //   component: ProfileComponent,
  //   children: [
  //     {
  //       path: "",
  //       loadComponent: () =>
  //         import("./pages/profile/profile.component").then(
  //           (m) => m.ProfileComponent
  //         ),
  //       data: { title: "User profile" },
  //     },
  //   ],
  // },
  // {
  //   path: webRoutes.profileNew,
  //   // component: ProfileNewComponent,
  //   children: [
  //     {
  //       path: "",
  //       loadComponent: () =>
  //         import("./pages/profilenew/profilenew.component").then(
  //           (m) => m.ProfileNewComponent
  //         ),
  //       data: { title: "User profile" },
  //     },
  //   ],
  // },
  {
    path: webRoutes.comingSoon,
    component: ComingSoonComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./components/coming-soon/coming-soon.component").then(
            (m) => m.ComingSoonComponent
          ),
        data: { title: "Coming soon" },
      },
    ],
  },
  // Dashboards
  {
    path: webRoutes.profile,
    loadComponent: () =>
      import("./pages/dashboard/components/profile/profile.component").then(
        (m) => m.ProfileMarvelerComponent
      ),
    data: { title: "Profile Marveler" },
  },
  {
    path: webRoutes.edit,
    loadComponent: () =>
      import("./pages/dashboard/components/edit-marveler/edit.component").then(
        (m) => m.EditMarvelerComponent
      ),
    data: { title: "Edit Info" },
  },

  {
    path: webRoutes.gallery,
    loadComponent: () =>
      import(
        "./pages/dashboard/components/edit-marveler/gallery/gallery.component"
      ).then((c) => c.GalleryComponent),
    data: { title: "Edit Info" },
  },
  {
    path: webRoutes.settings,
    loadComponent: () =>
      import("./pages/dashboard/components/setting/setting.component").then(
        (m) => m.SettingComponent
      ),
    data: { title: "Settings" },
  },
  {
    path: webRoutes.updateAuth,
    loadComponent: () =>
      import(
        "./pages/dashboard/components/setting/update-password/update-auth/update-auth.component"
      ).then((m) => m.UpdateAuthComponent),
    data: { title: "Update Auth" },
  },
  {
    path: webRoutes.favCounselors,
    loadComponent: () =>
      import(
        "./pages/dashboard/components/favorite-counselors/favorite-counselors.component"
      ).then((m) => m.FavoriteCounselorsComponent),
    data: { title: "Favorite Counselor" },
  },
  {
    path: webRoutes.pricing,
    loadComponent: () =>
      import("./pages/dashboard/components/pricing/pricing.component").then(
        (c) => c.PricingComponent
      ),
    data: { title: "Pricing" },
  },
  {
    path: webRoutes.availability,
    loadComponent: () =>
      import(
        "./pages/dashboard/components/availability/availability.component"
      ).then((m) => m.AvailabilityComponent),
    data: { title: "Availability" },
  },
  {
    path: webRoutes.explorer,
    loadComponent: () =>
      import("./pages/dashboard/components/explorer/explorer.component").then(
        (m) => m.ExplorerComponent
      ),
    data: { title: "Explorer" },
  },
  {
    path: webRoutes.counselorProfile,
    loadComponent: () =>
      import(
        "./pages/dashboard/components/counselor-profile/counselor-profile.component"
      ).then((m) => m.CounselorProfileComponent),
    data: { title: "Counselor" },
  },
  {
    path: webRoutes.reviews,
    loadComponent: () =>
      import("./pages/dashboard/components/reviews/reviews.component").then(
        (m) => m.ReviewsComponent
      ),
    data: { title: "Setting" },
  },
  {
    path: webRoutes.payment,
    loadComponent: () =>
      import("./pages/dashboard/components/payment/payment.component").then(
        (m) => m.PaymentComponent
      ),
    data: { title: "Setting" },
  },
  {
    path: webRoutes.booking,
    loadComponent: () =>
      import("./pages/dashboard/components/booking/booking.component").then(
        (m) => m.BookingComponent
      ),
    data: { title: "Setting" },
  },
  {
    path: webRoutes.earning,
    loadComponent: () =>
      import("./pages/dashboard/components/earning/earning.component").then(
        (m) => m.EarningComponent
      ),
    data: { title: "Setting" },
  },
  {
    path: webRoutes.checkout,
    loadComponent: () =>
      import("./pages/dashboard/components/checkout/checkout.component").then(
        (m) => m.CheckoutComponent
      ),
    data: { title: "Checkout" },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    children: [],
    canActivate: [authGuard],
  },
  {
    path: "**",
    redirectTo: webRoutes.comingSoon,
    pathMatch: "full",
  },
];
