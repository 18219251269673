import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SidebarComponent } from "../../layouts/sidebar/sidebar.component";
import { HeaderComponent } from "../../layouts/header/header.component";
import { SidebarService } from "../../layouts/sidebar/services/sidebar.service";
import { RequestService } from "../../shared/services/request.service";
import { CircularProgressBarComponent } from "../../circular-progress-bar/circular-progress-bar.component";
import { RouterLink } from "@angular/router";
import { CounselorComponent } from "./components/favorite-counselors/counselor/counselor.component";
import { gallery } from "../../shared/interfaces/Gallery";
import { MatDialog } from "@angular/material/dialog";
import { GalleryDialogComponent } from "./components/gallery-dialog/gallery-dialog.component";
import { ReviewsComponent } from "./components/my-reviews/components/reviews.component";
import { NewsComponent } from "./components/latest-news/news/news.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    SidebarComponent,
    HeaderComponent,
    CommonModule,
    CommonModule,
    CircularProgressBarComponent,
    RouterLink,
    ReviewsComponent,
    CounselorComponent,
    NewsComponent,
    MatIconModule,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild("sidebar") sidebarRef!: ElementRef;
  @ViewChild("header") headerRef!: ElementRef;
  isOpenSidebar: boolean = false;
  basicInfos: any;
  scoreValue1: number = 4.5;
  scoreValue2: number = 3.2;
  secondaryBackgroundColor = "#12676a82";
  secondaryColor = "#12676A";
  // primaryColor = "#9D0759";
  appointment: any = [];
  currentUser: any;
  host: any;
  statusList: any = [
    { value: "all", viewValue: "All" },
    { value: "active", viewValue: "Active" },
    { value: "pending", viewValue: "Pending" },
    { value: "inactive", viewValue: "Inactive" },
  ];
  counselorList = [];
  dialogRef: any;
  dialogHeight = "90vh";
  resizeObserver: ResizeObserver;
  gallery: gallery[];
  reviews: any = [];
  newsList = [
    {
      title: "News Title 1",
      subtitle: "News Subtitle 1",
      image: "assets/images/landspace.jpeg",
    },
    {
      title: "News Title 2",
      subtitle: "News Subtitle 2",
      image: "assets/images/landspace.jpeg",
    },
    {
      title: "News Title 2",
      subtitle: "News Subtitle 2",
      image: "assets/images/landspace.jpeg",
    },
  ];
  constructor(
    public sidebarService: SidebarService,
    private dialog: MatDialog,
    private requestService: RequestService
  ) {}
  async ngOnInit() {
    this.sidebarService.getSidebarState.subscribe((state) => {
      this.isOpenSidebar = state;
    });

    this.currentUser = this.requestService.currentUser;
    this.basicInfos = {
      firstName: this.currentUser?.firstName,
      lastName: this.currentUser?.lastName,
      bio: this.currentUser?.bio,
      profile_img:
        this.currentUser?.profile_img?.url ?? "assets/images/final favicon.png",
      keywords: this.currentUser?.keywords,
    };

    if (this.currentUser.roles.includes("marveler")) {
      let resultBooking = await this.requestService.getUserBookingByStatus(
        "pending"
      );
      this.appointment = resultBooking.docs[0];
      this.host = this.appointment?.host;
    }

    let resultReviews = await this.requestService.getUserBookingByStatus(
      "confirmed"
    );
    this.reviews = resultReviews.docs;
    this.counselorList = this.currentUser.favorite_counselors;

    this.resizeObserver = new ResizeObserver(() => {
      this.updateDialogHeight();
      if (this.dialogRef) {
        this.dialogRef.updateSize("90vw", this.dialogHeight);
      }
    });
    this.resizeObserver.observe(document.body);

    this.gallery = this.currentUser.gallery;
  }

  updateDialogHeight(): void {
    if (window.innerWidth <= 440) {
      this.dialogHeight = "40vh";
    } else if (window.innerWidth <= 780) {
      this.dialogHeight = "50vh";
    } else if (window.innerWidth <= 990) {
      this.dialogHeight = "60vh";
    } else {
      this.dialogHeight = "90vh";
    }
  }
  openDialog(index: number): void {
    this.updateDialogHeight();
    this.dialogRef = this.dialog.open(GalleryDialogComponent, {
      data: {
        imagesArray: this.gallery,
        selectedIndex: index,
      },
      width: "90vw",
      height: this.dialogHeight,
      panelClass: "full-screen-modal",
    });
  }
  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
