<div class="review">
    <div class="rating">
        <app-circular-progress-bar [score]="scoreValue1"></app-circular-progress-bar>
    </div>
    <div class="description">
        <div class="review-title limit-300">
            {{ review?.review }}
        </div>
        <div class="review-subtitle">
            {{ review?.host?.name  }}
        </div>
        <div class="review-subtitle">
            {{ review?.createdAt | date:'short' }}
        </div>
    </div>

</div>