<header class="header-section">
  <!-- Logo Section -->
  <div class="logo-container">
      <img src="/assets/img/img14.jpg" alt="Logo" class="logo">
  </div>
  <!-- Search Bar (Always Visible on Desktop, Toggles on Mobile) -->
  <div class="search-bar-container" [class.active]="isSearchOpen || !isMobile">
    <i class="fas fa-map-marker-alt search-input-icon"></i>
    <div class="search-input-container">
      <input type="text" class="search-input search-input-with-icon" placeholder="Where are you going?">
    </div>
    <button class="search-button">
      <i class="fas fa-calendar-alt"></i> Add dates
    </button>
    <button class="search-button">
      <i class="fas fa-users"></i> Add people
    </button>
    <button class="match-button">Match me</button>
  </div>

  <!-- Search Bar Section 
  <div class="search-bar-container">
    <i class="fas fa-map-marker-alt search-input-icon"></i>
      <div class="search-input-container">
              
          <input type="text" class="search-input search-input-with-icon" placeholder="Where are you going?">
      </div>
      <button class="search-button">
          <i class="fas fa-calendar-alt"></i>
          Add dates
      </button>
      <button class="search-button">
          <i class="fas fa-users"></i>
          Add people
      </button>
      <button class="match-button">Match me</button>
  </div>-->

  <!-- Menu Section -->
  <div class="menu-container">
      <button class="menu-toggle" (click)="toggleSearchMenu()" >
          <span class="menu-icon"></span>
      </button>
  </div>
</header>


