
<app-header-new></app-header-new>
<!-- Hero Section -->
<section class="hero">
  <!-- <div class="logo"> -->
    <!--<img src="/assets/img/img14.webp" alt="Marv Logo" /> -->
  <!-- </div> -->
  <div class="hero-content">
    <h1>YOUR TRAVEL EXPERIENCE - YOUR STYLE</h1>
    <p>
      Answer the test questions to find out what type of traveler you are and get matched with your
      ideal local guide
    </p>
  </div>
  <button class="test-button">Take personality test</button>
</section>

<!-- What You Get Section -->
<section class="benefits">
  <div class="left-img"></div>
  <div class="right-content">
    <h2>WHAT YOU GET WHEN YOU CHOOSE MARV</h2>

    <div class="register-buttons">
      <button class="register-btn traveler">Traveler</button>
      <button class="register-btn guide">Local Guide</button>
    </div>
    <div class="benefits-grid">
      <div class="benefit-card">
        <img src="/assets/img/online-meetings.webp" alt="Online Meeting" />
        <h3>Online Meeting</h3>
        <p>
          Meet your local guide online
          to get recommendations, culture insights, and design
          your personalized itinerary
        </p>
      </div>
      <div class="benefit-card">
        <img
          src="/assets/img/personalized.webp"
          alt="Personalized Experience"
        />
        <h3>Personalized Experience</h3>
        <p>
          every part of your experience is personalized
          based on your interests, passions, and desires. Even
          the match with your guide!          
        </p>
      </div>
      <div class="benefit-card">
        <img src="/assets/img/support.webp" alt="Community Support" />
        <h3>Community Support</h3>
        <p>
          when you join Marv, you join an inclusive community of passionate travelers and
          locals, ready to support you
          on your trip
        </p>
      </div>
    </div>
  </div>
</section>

<!-- Registration Section -->
<section class="register">
  <div class="register-container">
    <h2>Register now as a</h2>
    <div class="register-buttons2">
      <button class="register-btn traveler2">Traveler</button>
      <button class="register-btn guide">Local Guide</button>
    </div>
  </div>
</section>

<!-- Destinations Section -->
<section class="destinations">
  <h2>FIND YOUR LOCAL IN ONE OF OUR TOP DESTINATIONS</h2>
  <div class="carousel-container">
    <button class="nav-button left" (click)="slideLeft()">&lt;</button>
    <div class="carousel">
      <div class="cards-wrapper" #destinationsGrid>
        <div class="card" *ngFor="let destination of destinations">
          <img [src]="destination.img" [alt]="destination.city" />
          <div class="location">
            <i class="fas fa-map-marker-alt"></i> {{ destination.city }}
          </div>
          <div class="info">
            <i class="fas fa-user-friends"></i> {{ destination.guides }} Local Guides
          </div>
        </div>
      </div>
    </div>
    <button class="nav-button right" (click)="slideRight()">&gt;</button>
  </div>
</section>




<!-- Local Guide Section -->
<section class="local-guide">
  <h2>MATCH WITH A LOCAL GUIDE BASED ON YOUR SHARED INTERESTS</h2>
  <div class="carousel-container">
    <button class="nav-button left" (click)="slideLeftLocal()">&lt;</button>
    <div class="carousel">
      <div class="cards-wrapper" #localGuidesGrid>
        <div class="local-card" *ngFor="let guide of localGuides">
          <img [src]="guide.img" [alt]="guide.name" />
          <div class="local-info">
            <i class="icon-interests"></i> {{ guide.name }}, {{ guide.city }}
          </div>
        </div>
      </div>
    </div>
    <button class="nav-button right" (click)="slideRightLocal()">&gt;</button>
  </div>
</section>


<!-- keywords button -->
<div class="keywords-button-wrapper">
  <button class="set-keywords-btn">Set Your Keywords</button>
</div>

<!-- Latest Articles Section -->
<section class="articles">
  <h2>LATEST ARTICLES</h2>
  <div class="articles-carousel-container">
    <button class="nav-button left" (click)="slideLeftArticles()">&lt;</button>
    <div class="articles-carousel">
      <div class="articles-wrapper" #articlesGrid>
        <div class="article-card" *ngFor="let article of articles">
          <img [src]="article.img" [alt]="article.title" />
          <h3>{{ article.title }}</h3>
        </div>
      </div>
    </div>
    <button class="nav-button right" (click)="slideRightArticles()">&gt;</button>
  </div>
</section>



<!-- Gallery Grid -->
<section class="gallery">
  <img src="/assets/img/images-block.webp" alt="Travel moment" />
  <div class="gallery-text">
    <p>
      Unlock the secrets of authentic travel—subscribe to our newsletter and be
      the first to discover hidden gems, expert tips, and exclusive
      opportunities with Marv!
    </p>
    <button class="subscribe-btn">Subscribe</button>
  </div>
</section>
<section class="become-local-guide">
  <div class="image">
    <!-- <img src="/assets/img/img50.jpg" alt="Group of people exploring a city" /> -->
  </div>
  <div class="content">
    <h1>Become a Local Guide</h1>
    <p>
      Have you ever thought about sharing the hidden gems of your city with
      travelers from around the world? At Marv, you don’t need years of
      experience to become a local guide—just a genuine love for your city and
      the desire to show others what makes it special.
    </p>
    <p>
      Join our community and turn your passion for your city into an exciting
      opportunity to meet people, share stories, and even earn on your own
      terms.
    </p>
    <div class="button">
      <a href="#">Register</a>
    </div>
  </div>
</section>
<section class="container">
  <div class="content">
    <h1>Our Pet Initiative</h1>
    <p>
      Meet Milo, our CPO - Chief Pet Officer, who takes part in all the board
      meetings, representing Marv's commitment towards animal fares and pet
      communities.
    </p>
    <p>
      The Marv Tech culture includes efforts and dedication within the pet
      community, supporting our both internal and external stakeholders. We
      support our partners' furry companions, as well as collaborate with local
      shelters, clinics, and rescuing organizations in pursuing our vision of a
      world where animals are safe and loved in inclusive families.
    </p>
    <p>
      Milo’s presence at Marv is a daily reminder of the joy pets bring to our
      lives. Milo is not just a mascot, he is an active member of the board. He
      might not be able to communicate all the decisions he would like to take,
      but we make sure we involve him in all the activities - he is ready to
      pawpprove any beneficial initiative for the pets.
    </p>
  </div>
  <div class="image">
    <img src="/assets/img/img51.jpg" alt="Milo the Chief Pet Officer" />
  </div>
</section>
<footer class="footer">
  <div class="footer-container">
    <div class="footer-column">
      <h3>Pages</h3>
      <ul>
        <li><a href="#">Services</a></li>
        <li><a href="#">Press Release</a></li>
        <li><a href="#">Contact</a></li>
        <li><a href="#">Privacy Policy</a></li>
        <li><a href="#">Terms & Conditions</a></li>
        <li><a href="#">Cookies Policy</a></li>
      </ul>
    </div>
    <div class="footer-column">
      <h3>Address</h3>
      <p>
        Marv Tech SRL<br />CUI 49778952<br />Aleea Constantin Sandu-Aldea,<br />Bucharest,
        Romania
      </p>
    </div>
    <div class="footer-column socials">
      <h3>Socials</h3>
      <ul>
        <li><a href="#">Instagram</a></li>
        <li><a href="#">LinkedIn</a></li>
        <li><a href="#">Facebook</a></li>
        <li><a href="#">Pinterest</a></li>
        <li><a href="#">Threads</a></li>
        <li><a href="#">Tiktok</a></li>
      </ul>
    </div>
    <div class="footer-column">
      <h3>Contact Us</h3>
      <form class="contact-form">
        <input type="text" placeholder="Your name" />
        <input type="email" placeholder="Your email" />
        <textarea rows="4" placeholder="Your message"></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  </div>
</footer>
