import { Component, Input, HostListener  } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header-new',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderNewComponent {
  isMobile: boolean = window.innerWidth <= 600;
  isSearchOpen: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 600;
  }

  toggleSearchMenu() {
    if (this.isMobile) {
      this.isSearchOpen = !this.isSearchOpen;
    }
  }
}
