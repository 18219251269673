<div class="news-container">
    <div class="news">
        <img [src]="news.image" alt="" class="news-image">
        <div class="news-details">
            <div class="title">
            <div class="title-heading">{{ news.title }}</div>
            <div class="title-subtitle">{{ news.subtitle }}</div>
            </div>
        </div>
    </div>
</div>
  