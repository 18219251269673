import { Component, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNewComponent } from '../../components/header/header.component';
import { FeaturesComponent } from '../../components/features/features.component';
import { GalleryComponent } from '../../components/gallery/gallery.component';


@Component({
  selector: 'app-home-green',
  standalone: true,
  imports: [CommonModule, HeaderNewComponent, FeaturesComponent, GalleryComponent],
  templateUrl: './home-green.component.html',
  styleUrl: './home-green.component.scss'
})
export class HomeGreenComponent {

  @ViewChild('destinationsGrid', { static: false }) destinationsGrid!: ElementRef;
  currentIndexDestinations = 0;
  cardWidthDestinations = 0;
  totalCardsDestinations = 0;
  visibleCardsDestinations = 3; 

  @ViewChild('localGuidesGrid', { static: false }) localGuidesGrid!: ElementRef;
  currentIndexGuides = 0;
  cardWidthGuides = 0;
  totalCardsGuides = 0;
  visibleCardsGuides = 4;

  @ViewChild('articlesGrid', { static: false }) articlesGrid!: ElementRef;

  currentIndexArticles = 0;
  cardWidthArticles = 0;
  totalCardsArticles = 0;
  visibleCardsArticles = 3; 

  destinations = [
    { img: '/assets/img/img16.jpg', city: 'Bucharest, Romania', guides: 23 },
    { img: '/assets/img/img17.jpg', city: 'Naples, Italy', guides: 11 },
    { img: '/assets/img/img46.jpg', city: 'Porto, Portugal', guides: 37 },
    { img: '/assets/img/img48.jpg', city: 'Madrid, Spain', guides: 15 },
    { img: '/assets/img/img49.jpg', city: 'Berlin, Germany', guides: 30 },
    { img: '/assets/img/img46.jpg', city: 'Porto, Portugal', guides: 37 },
    { img: '/assets/img/img48.jpg', city: 'Madrid, Spain', guides: 15 },
    { img: '/assets/img/img49.jpg', city: 'Berlin, Germany', guides: 30 }
  ];

  localGuides = [
    { img: '/assets/img/img16.jpg', name: 'Alex', city: 'Stockholm' },
    { img: '/assets/img/img17.jpg', name: 'Eliana', city: 'Amsterdam' },
    { img: '/assets/img/img46.jpg', name: 'Ruben', city: 'Berlin' },
    { img: '/assets/img/img48.jpg', name: 'Sophie', city: 'Paris' },
    { img: '/assets/img/img49.jpg', name: 'Marco', city: 'Rome' },
    { img: '/assets/img/img46.jpg', name: 'Ruben', city: 'Berlin' },
    { img: '/assets/img/img48.jpg', name: 'Sophie', city: 'Paris' },
    { img: '/assets/img/img49.jpg', name: 'Marco', city: 'Rome' }
  ];

  articles = [
    { img: '/assets/img/img47.jpg', title: 'The Limits of Standard Travel Recommendations' },
    { img: '/assets/img/img51.jpg', title: 'Why Travel Planning Needs More Than Algorithm Templates' },
    { img: '/assets/img/img34.jpg', title: 'Tourist Traps: How Following the Crowd Ruins Your Trip' },
    { img: '/assets/img/img50.jpg', title: 'The Best Hidden Cafes in Europe' },
    { img: '/assets/img/img47.jpg', title: 'How Locals Make Your Travel Experience Unique' }
  ];

  

  ngAfterViewInit() {
    this.initializeCarousels();
  }

  initializeCarousels() {
    setTimeout(() => {
      // Initialize Destinations
      const gridDest = this.destinationsGrid.nativeElement;
      const cardDest = gridDest.querySelector('.card');
      if (cardDest) {
        const gridGap = parseInt(getComputedStyle(gridDest).gap || '20', 10);
        this.cardWidthDestinations = cardDest.offsetWidth + gridGap;
      }
      this.totalCardsDestinations = gridDest.querySelectorAll('.card').length;

      // Initialize Local Guides
      const gridGuides = this.localGuidesGrid.nativeElement;
      const cardGuides = gridGuides.querySelector('.local-card');
      if (cardGuides) {
        const gridGap = parseInt(getComputedStyle(gridGuides).gap || '20', 10);
        this.cardWidthGuides = cardGuides.offsetWidth + gridGap;
      }
      this.totalCardsGuides = gridGuides.querySelectorAll('.local-card').length;
      // Articles Carousel Setup
      const articlesGrid = this.articlesGrid.nativeElement;
      const articleCard = articlesGrid.querySelector('.article-card');
      
      if (articleCard) {
        const gridGap = parseInt(getComputedStyle(articlesGrid).gap || '20', 10);
        this.cardWidthArticles = articleCard.offsetWidth + gridGap;
      }

      this.totalCardsArticles = articlesGrid.querySelectorAll('.article-card').length;
      

    }, 100);
  }

  slideLeft() {
    if (this.currentIndexDestinations > 0) {
      this.currentIndexDestinations--;
      this.updateCarousel(this.destinationsGrid, this.currentIndexDestinations, this.cardWidthDestinations);
    }
  }

  slideRight() {
    if (this.currentIndexDestinations < this.totalCardsDestinations - this.visibleCardsDestinations) {
      this.currentIndexDestinations++;
      this.updateCarousel(this.destinationsGrid, this.currentIndexDestinations, this.cardWidthDestinations);
    }
  }

  slideLeftLocal() {
    if (this.currentIndexGuides > 0) {
      this.currentIndexGuides--;
      this.updateCarousel(this.localGuidesGrid, this.currentIndexGuides, this.cardWidthGuides);
    }
  }

  slideRightLocal() {
    if (this.currentIndexGuides < this.totalCardsGuides - this.visibleCardsGuides) {
      this.currentIndexGuides++;
      this.updateCarousel(this.localGuidesGrid, this.currentIndexGuides, this.cardWidthGuides);
    }
  }

  slideLeftArticles() {
    if (this.currentIndexArticles > 0) {
      this.currentIndexArticles--;
      this.updateCarousel(this.articlesGrid, this.currentIndexArticles, this.cardWidthArticles);
    }
  }
  
  slideRightArticles() {
    if (this.currentIndexArticles < this.totalCardsArticles - this.visibleCardsArticles) {
      this.currentIndexArticles++;
      this.updateCarousel(this.articlesGrid, this.currentIndexArticles, this.cardWidthArticles);
    }
    console.log('Sliding Left - Articles');
  console.log('Current Index:', this.currentIndexArticles);
  console.log('Total Cards:', this.totalCardsArticles);
  console.log('Visible Cards:', this.visibleCardsArticles);
  console.log('Card Width:', this.cardWidthArticles);
  console.log('TranslateX:', `-${this.currentIndexArticles * this.cardWidthArticles}px`);
  }
  

  updateCarousel(gridRef: ElementRef, currentIndex: number, cardWidth: number) {
    const grid = gridRef.nativeElement;
    const newTransform = `translateX(-${currentIndex * cardWidth}px)`;
    grid.style.transform = newTransform;
  }


  features = [
    {
      icon: '/assets/icons/expert.png',
      title: 'Expert Local Guides',
      description: 'Verified locals who know their city inside and out'
    },
    {
      icon: '/assets/icons/custom.png',
      title: 'Custom Experiences',
      description: 'Tailored itineraries based on your interests'
    },
    {
      icon: '/assets/icons/authentic.png',
      title: 'Authentic Adventures',
      description: 'Experience the city like a true local'
    }
  ];

  galleryImages = [
    '/assets/destinations/arc.jpg',
    '/assets/destinations/street.jpg',
    '/assets/destinations/cafe.jpg'
  ];

  reviews = [
    {
      image: '/assets/reviews/review1.jpg',
      name: 'John Smith',
      location: 'Paris, France',
      text: 'Amazing experience with our local guide! They showed us hidden gems we would never have found on our own.'
    },
    {
      image: '/assets/reviews/review2.jpg',
      name: 'Maria Garcia',
      location: 'Barcelona, Spain',
      text: 'Our guide was knowledgeable and friendly. Made our trip truly memorable!'
    },
    {
      image: '/assets/reviews/review3.jpg',
      name: 'James Wilson',
      location: 'Rome, Italy',
      text: 'Perfect way to explore the city with a local perspective. Highly recommended!'
    },
    {
      image: '/assets/reviews/review4.jpg',
      name: 'Sophie Chen',
      location: 'Tokyo, Japan',
      text: 'Wonderful experience! Our guide helped us navigate the city like locals.'
    }
  ];

  articless = [
    {
      image: '/assets/articles/cafe.jpg',
      title: 'Best Hidden Cafes',
      excerpt: 'Discover the coziest spots in town'
    },
    {
      image: '/assets/articles/market.jpg',
      title: 'Local Markets Guide',
      excerpt: 'Experience authentic local life'
    },
    {
      image: '/assets/articles/tour.jpg',
      title: 'Walking Tours',
      excerpt: 'See the city on foot'
    }
  ];
}
