import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';


@Component({
  selector: 'app-news',
  standalone: true,
  imports: [
    MatInputModule, 
    MatIconModule, 
    MatSelectModule, 
    ReactiveFormsModule, 
    CommonModule, 
    MatMenuModule, 
  ],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent {
  filter: any = new FormControl("all"); 
  @Input() news: { title: string; subtitle: string; image: string };

  constructor(private router: Router) {}
}
