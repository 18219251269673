import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { inject } from "@angular/core";
import { RequestService } from "../services/request.service";

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const requestService = inject(RequestService);

  if (
    requestService.currentUser &&
    (requestService.currentUser.roles.includes("marveler") ||
      requestService.currentUser.roles.includes("counselor"))
  )
    return true;

  return false;
};
