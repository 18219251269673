import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-gallery-dialog",
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: "./gallery-dialog.component.html",
  styleUrls: ["./gallery-dialog.component.scss"],
})
export class GalleryDialogComponent {
  imagesArray: any[];
  selectedIndex: number;
  color: string = "var(--primary-color)";

  constructor(
    public dialogRef: MatDialogRef<GalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imagesArray = data.imagesArray;
    this.selectedIndex = data.selectedIndex;
  }

  ngOnInit(): void {
    let userType = localStorage.getItem("userType");
    if (userType === "marveler") {
      this.color = "var(--primary-color)";
    } else if (userType === "counselor") {
      this.color = "var(--secondary-color)";
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  prevImage() {
    this.selectedIndex =
      this.selectedIndex > 0
        ? this.selectedIndex - 1
        : this.imagesArray.length - 1;
  }

  nextImage() {
    this.selectedIndex =
      this.selectedIndex < this.imagesArray.length - 1
        ? this.selectedIndex + 1
        : 0;
  }
}
