import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { RequestService } from "../services/request.service";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private requestService: RequestService) {}

  intercept(request: HttpRequest<Credential>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        Authorization: `${this.requestService.currentUser?.token}`,
      },
    });
    return next.handle(request);
  }
}
