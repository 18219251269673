<div #header>
  <app-header class="header"></app-header>
</div>
<div class="wrapper">
  <div class="sidebar" #sidebar [ngClass]="{ open: isOpenSidebar }">
    <app-sidebar></app-sidebar>
  </div>
  <div class="inner">
    <div class="dashboard-container">
      <div class="row">
        <div class="main">
          <div class="overview">
            <img class="poster" [src]="basicInfos?.profile_img" alt="" />
            <div class="description">
              <div class="name">
                {{ basicInfos?.firstName }} {{ basicInfos?.lastName }}
              </div>
              <div class="bio">
                <div class="title">
                  Biography
                  <span class="icon">
                    <mat-icon class="img">edit</mat-icon>
                  </span>
                </div>
                <div class="desc limit-300">
                  {{ basicInfos?.bio }}
                </div>
              </div>
              <div class="keywords">
                <div class="title">
                  keywords
                  <span class="icon">
                    <mat-icon class="img">edit</mat-icon>
                  </span>
                </div>
                <div class="keywords-container">
                  <ng-container
                    *ngFor="let keyword of basicInfos?.keywords; let i = index"
                  >
                    @if (i < 5) {
                    <span class="keyword">
                      {{ keyword?.keyword_id?.name }}
                    </span>
                    }
                  </ng-container>
                  <div class="see-all" routerLink="/edit">
                    See all {{ basicInfos?.keywords?.length }} keywords
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section2">
            <div class="card card-light" *ngIf="appointment">
              <div class="title">
                <img src="assets/icons/booking-pink.svg" alt="" />
                BOOKINGS
              </div>
              <div class="booking-details">
                <div class="personal-info">
                  <div class="basic-info">
                    <img
                      class="profile-pic"
                      [src]="
                        host?.profile_img?.url ??
                        'assets/images/final favicon.png'
                      "
                      alt=""
                    />
                    <div class="name-location">
                      <div class="name">
                        {{ host?.firstName }} {{ host?.lastName }}
                      </div>
                      <div class="location">
                        {{ host?.city }}, {{ host?.nationality?.name }}
                      </div>
                    </div>
                  </div>
                  <div class="date">
                    {{ appointment.start | date : "shortDate" }}
                  </div>
                  <div class="contact-info">
                    <div class="number">
                      {{ host?.phoneNumber }}
                    </div>
                    <div class="email">
                      <!-- {{host?.email}} -->
                    </div>
                  </div>
                </div>
                <div class="rating">
                  <app-circular-progress-bar
                    [score]="scoreValue1"
                    [backgroundStroke]="secondaryBackgroundColor"
                    [progressStroke]="secondaryColor"
                    [textColor]="secondaryColor"
                  >
                  </app-circular-progress-bar>
                </div>
              </div>
              <a
                class="see-more"
                [routerLink]="['/booking']"
                routerLinkActive="active"
              >
                see more...
              </a>
            </div>
            <div class="card card-light"  *ngIf="currentUser.role == 'marveler'">
              <div class="title">
                <mat-icon class="img">diversity_1</mat-icon>
                <!-- <img src="assets/icons/favorite-counselor-pink.svg" alt="" /> -->
                FAVORITE COUNSELORS
              </div>
              <div class="booking-details fav-counselor-details">
                @for (counselor of counselorList; track $index) { @if ($index <
                2) {
                <div class="counselor">
                  <app-counselor [counselor]="counselor"></app-counselor>
                </div>
                } }
              </div>
              <a
                class="see-more"
                [routerLink]="['/counselors']"
                routerLinkActive="active"
              >
                see more...
              </a>
            </div>
            <div class="card card-light" *ngIf="currentUser.role == 'counselor'">
              <div class="title">
                <img src="assets/icons/career-path-green.svg" alt="" />
                CAREER PATH
              </div>
              <div class="booking-details fav-counselor-details">
                <div class="counselor-container">
                  <div class="main">
                    <img
                      class="profile-pic"
                      src="assets/icons/meet-happy.png"
                      alt=""
                    />
                    <div class="title">LOCAL BUDDY</div>
                    <app-circular-progress-bar
                      [score]="56"
                      [backgroundStroke]="secondaryBackgroundColor"
                      [progressStroke]="secondaryColor"
                      [textColor]="secondaryColor"
                    >
                    </app-circular-progress-bar>
                  </div>
                  <div class="recommendations">
                    <div>Recommendations</div>
                    <li>Improve communication skills</li>
                    <li>Diversificate itineraries</li>
                    <li>Reach 100 bookings</li>
                  </div>
                </div>
              </div>
              <div class="see-more">
                <a> see more... </a>
              </div>
            </div>
          </div>
          <div class="section2">
            <div class="gallery">
              <div class="title">MY GALLERY</div>
              <div class="gallery-container">
                <div
                  *ngFor="let image of gallery; let i = index"
                  class="gallery-item"
                  (click)="openDialog(i)"
                >
                  <img [src]="image.url" [alt]="image.alt" />
                </div>
                <div class="see-all" (click)="openDialog(0)">See All</div>
              </div>
            </div>
            <div class="my-reviews">
              <div class="title">MY REVIEWS</div>
              <div *ngFor="let review of reviews; let i = index">
                @if (i < 2) {
                <app-reviews [review]="review"></app-reviews>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside style="display: none;">
        <div class="dropdown">
          <div class="dropbtn">
            <mat-icon class="arrow">newspaper</mat-icon>
            <div class="dropbtn-name">
              <div>Latest News</div>
            </div>
            <mat-icon class="arrow">keyboard_arrow_down</mat-icon>
          </div>
          <div class="dropdown-content">
            <a href="#">From Marv</a>
            <a href="#">From Counselor</a>
            <a href="#">From My Network</a>
          </div>
        </div>
        <div class="news-container">
          <!-- <div>Coming Soon</div> -->
          <div *ngFor="let newsItem of newsList">
            <app-news [news]="newsItem"></app-news>
          </div>
        </div>
      </aside>
    </div>
  </div>
</div>
