import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CircularProgressBarComponent } from '../../../../../circular-progress-bar/circular-progress-bar.component';

@Component({
  selector: 'app-reviews',
  standalone: true,
  imports: [
    MatInputModule, 
    MatIconModule, 
    MatSelectModule, 
    ReactiveFormsModule, 
    CommonModule, 
    MatMenuModule, 
    CircularProgressBarComponent
  ],
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss'
})
export class ReviewsComponent {
  scoreValue1: number = Math.floor(Math.random() * 6);  
  secondaryBackgroundColor = "#12676a82";
  secondaryColor = "#12676A";
  primaryColor = "#9D0759";
  filter: any = new FormControl("all");
  @Input() review: any;

  constructor(private router: Router) {}
}
