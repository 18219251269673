 <!-- Hero Section -->
 <section class="hero">
  <div class="logo">MARV</div>
  <div class="hero-content">
    <h1>YOUR TRAVEL EXPERIENCE - YOUR STYLE</h1>
    <p>Join us on our quest to find the right style of your travel experience. Take our personality test and find your perfect local guide.</p>
    <button class="test-button">Take personality test</button>
  </div>
</section>

<!-- What You Get Section -->
<section class="benefits">
  <h2>WHAT YOU GET WHEN YOU CHOOSE MARV</h2>
  <div class="benefits-grid">
    <div class="benefit-card">
      <img src="/assets/img/img14.jpg" alt="Online Meeting">
      <h3>Online Meeting</h3>
      <p>Get to know your guide before your trip through a personal meeting.</p>
    </div>
    <div class="benefit-card">
      <img src="/assets/img/img34.jpg" alt="Personalized Experience">
      <h3>Personalized Experience</h3>
      <p>A unique travel experience tailored to your interests and preferences.</p>
    </div>
    <div class="benefit-card">
      <img src="/assets/img/img35.jpg" alt="Community Support">
      <h3>Community Support</h3>
      <p>Join our vibrant community of travelers and locals ready to share their experiences.</p>
    </div>
  </div>
</section>

<!-- Registration Section -->
<section class="register">
  <h2>Register now as a</h2>
  <div class="register-buttons">
    <button class="register-btn traveler">Traveler</button>
    <button class="register-btn guide">Local Guide</button>
  </div>
</section>

<!-- Destinations Section -->
<section class="destinations">
  <h2>FIND YOUR LOCAL IN ONE OF OUR TOP DESTINATIONS</h2>
  <div class="destinations-grid">
    <div class="destination-card">
      <img src="/assets/img/img16.jpg" alt="Bucharest, Romania">
      <span class="location">Bucharest, Romania</span>
    </div>
    <div class="destination-card">
      <img src="/assets/img/img17.jpg" alt="Berlin, Germany">
      <span class="location">Berlin, Germany</span>
    </div>
    <div class="destination-card">
      <img src="/assets/img/img46.jpg" alt="Porto, Portugal">
      <span class="location">Porto, Portugal</span>
    </div>
  </div>
</section>

<!-- Latest Articles -->
<section class="articles">
  <h2>LATEST ARTICLES</h2>
  <div class="articles-grid">
    <div class="article-card">
      <img src="/assets/img/img47.jpg" alt="The Limits of Individual Local Recommendations">
      <h3>The Limits of Individual Local Recommendations</h3>
    </div>
    <div class="article-card">
      <img src="/assets/img/img51.jpg" alt="Why Travel Planning">
      <h3>Why Travel Planning Needs More Than Algorithm Templates</h3>
    </div>
    <div class="article-card">
      <img src="/assets/img/img34.jpg" alt="Tourist Traps">
      <h3>Tourist Traps: How Avoiding the Crowds Can Ruin Your Trip</h3>
    </div>
  </div>
</section>

<!-- Gallery Grid -->
<section class="gallery">
  <div class="gallery-grid">
    <img src="/assets/img/img14.jpg" alt="Travel moment">
    <img src="/assets/img/img16.jpg" alt="Travel moment">
    <img src="/assets/img/img17.jpg" alt="Travel moment">
    <img src="/assets/img/img34.jpg" alt="Travel moment">
    <img src="/assets/img/img35.jpg" alt="Travel moment">
    <img src="/assets/img/img46.jpg" alt="Travel moment">
    <img src="/assets/img/img47.jpg" alt="Travel moment">
    <img src="/assets/img/img51.jpg" alt="Travel moment">
  </div>
  <div class="gallery-text">
    <p>Unlock the secrets of authentic travel—subscribe to our newsletter and be the first to discover hidden gems, expert tips, and exclusive opportunities with Marv!</p>
    <button class="subscribe-btn">Subscribe</button>
  </div>
</section>