<div class="dialog-content">
    <button class="close" (click)="close()" [ngStyle]="{'background-color': color}">
      <mat-icon>close</mat-icon>
    </button>
    <div class="carousel">
      <button class="prev" (click)="prevImage()" [ngStyle]="{'background-color': color}">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <img [src]="imagesArray[selectedIndex].url" [alt]="imagesArray[selectedIndex].title">
      <button mat-icon-button class="next" (click)="nextImage()" [ngStyle]="{'background-color': color}">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
</div>
  