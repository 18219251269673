<div class="menu-container">
  <button class="menu-toggle" (click)="toggleMenu()">
      <span class="menu-icon"></span>
  </button>
  
  <div class="menu-items" [class.active]="isOpen">
      <a routerLink="/green" routerLinkActive="active" (click)="toggleMenu()">Green Theme</a>
      <a routerLink="/pink" routerLinkActive="active" (click)="toggleMenu()">Pink Theme</a>
  </div>
</div>
